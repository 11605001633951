<template>
  <TaskDatePickerPopover
    v-model:open="open"
    :start-date="value.startDate"
    :due-date="value.dueDate"
    class="w-full h-full"
    :disabled="disabled"
    v-bind="$attrs"
    :trigger-class="triggerClass"
    @click.exact.stop.prevent
    @update:open="onUpdateOpen"
    @change="handleDateChange"
  >
    <UButton
      size="2xs"
      variant="soft"
      color="white"
      class="text-gray-900 w-full h-full py-0 !ring-0"
      :disabled="disabled"
    >
      <div v-if="value.startDate || value.dueDate" class="flex items-center justify-between w-full">
        <TaskDatesFormat
          class="text-start leading-4 whitespace-pre-wrap text-wrap flex flex-wrap items-center h-full font-normal"
          :start="value.startDate"
          :end="value.dueDate"
          :format="{
            time: ''
          }"
        />

        <UButton
          color="gray"
          variant="ghost"
          size="2xs"
          icon="i-heroicons-x-mark"
          class="group-hover/cell:flex hidden"
          @click.prevent.stop="onClear"
        />
      </div>
      <UButton
        v-else
        variant="ghost"
        color="white"
        class="rounded-full w-full px-0 hover:bg-transparent !ring-0"
        icon="i-heroicons-calendar"
        size="2xs"
        :disabled="disabled"
      >
        <div v-if="alertMessage" class="flex items-center justify-end w-full">
          <UTooltip
            :text="alertMessage"
            :popper="{
              placement: 'top',
              arrow: true,
            }"
          >
            <Icon
              name="heroicons:exclamation-triangle-20-solid"
              size="16"
              class="text-yellow-500"
            />
          </UTooltip>
        </div>
      </UButton>
    </UButton>
  </TaskDatePickerPopover>
</template>

<script lang="ts" setup>
import type { TaskFieldRef } from '#field/types'
import type { TaskDates } from '#task/types'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  },
  value: {
    type: [Object] as PropType<TaskDates>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },

  alertMessage: {
    type: String,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const { updateTasks } = useUpdateTask()

const open = ref(false)

const handleDateChange = (dates: TaskDates) => {
  updateTasks([props.taskId], dates, true)
  open.value = false
  emit('update:open', false)
}

const handleOpen = (setOpen = true) => {
  open.value = setOpen
}

const onUpdateOpen = (value: boolean) => {
  open.value = value
  emit('update:open', value)
}

const onClear = () => {
  handleDateChange({ startDate: null, dueDate: null })
}

defineExpose<TaskFieldRef>({
  open: () => {
    handleOpen(true)
  },
  close: () => {
    handleOpen(false)
  },
  delete: () => {
    onClear()
  },
})
</script>
